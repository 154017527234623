import { List, Site } from "@libry-content/types";
import { getFirstThreePublicationsWithImage } from "./bookLists";
import { getSiteUrl } from "./currentSite";

export const getBookCoverOGImageUrl = (site: Site, imageSrc?: string) =>
  `${getDomain(site)}/api/og-images/book-cover/${encodeURIComponent(
    imageSrc ??
      "" /* Fallback to empty string (""). The endpoint will then return a fallback-image to use for books where we don't have a cover-image*/
  )}`;

export const getBookFanOGImageUrl = (list: List, site: Site) => {
  const imageAssetIds = getFirstThreePublicationsWithImage(list.publications).map(
    (publication) => publication.image.asset._ref
  );
  return `${getDomain(site)}/api/og-images/bookfan/${imageAssetIds.join(",")}`;
};

export const getAgentOgImageUrl = (site: Site, imageSrc?: string) =>
  `${getDomain(site)}/api/og-images/agent/${encodeURIComponent(
    imageSrc ??
      "" /* Fallback to empty string (""). The endpoint will then return a fallback-image to use for agents where we don't have an image*/
  )}`;

export const getFallbackOgImageUrl = (site?: Site) => (site ? `${getDomain(site)}/api/og-images/fallback` : undefined);

const getDomain = (site: Site) =>
  getSiteUrl(site, {
    skipLanguage: true,
  });
